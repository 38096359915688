<template>
  <div>
    <div>
      <div class="helper">
        <ul class="list-inline mb-2">
          <li>
            <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
          </li>
        </ul>
      </div>
    </div>
    <div>
      <b-sidebar id="sidebar-1" title="Resumen" shadow bg-variant="dark" text-variant="light" width="450px"
        right backdrop>
        <div class="px-2 py-2">
          <p>Este paso muestra un resumen de la planificación con N° de demandas y líneas de la planificación, junto con los días productivos. Revisado lo anterior, se crea la planificación, cuyos detalles pueden verse en el histórico.</p>            
        </div>
      </b-sidebar>
    </div>
    <div>
      <b-row>
        <b-col md="6" lg="6" v-for="stat in resume" :key="stat.id" v-if="stat.name =='Modelo de Optimización'">
          <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
        </b-col>
        <b-col md="6" lg="6" v-for="stat in resume" :key="stat.id" v-if="stat.name =='Función Objetivo Seleccionada'">
          <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" lg="4" v-for="stat in resume" :key="stat.id" v-if="stat.name !='Función Objetivo Seleccionada' && stat.name != 'Modelo de Optimización'">
          <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import Stat from '../../../base/components/Stat.vue'


export default {
  props: {
    resume: {
      type: Array,
      default: [],
    },
  },

  components: {
    BRow,
    BCol,
    Stat,
  },

  methods: {

  },

  data() {
    return {
    }
  }
}
</script>
<style>
.helper {
  text-align: end;
}
</style>