<template>
  <section>
    <b-row>
      <b-col>
        <BrunaForm ref="generalRef" :fields="this.general.fields" :card="this.general.card" @change="generalChange">
          <template v-slot:footer> </template>
        </BrunaForm>
      </b-col>
    </b-row>

    <b-row v-if="debug">
      <b-col>
        <b-card>
          <div class="card-header">
            <!-- Title & SubTitle -->
            <div>
              <b-card-title></b-card-title>
              <b-card-sub-title class="mt-0"></b-card-sub-title>
            </div>
          </div>

          <b-card-body>
            <bruna-fn-obj-constructor ref="bruna_fn_obj_cons" :metrics="metrics" v-model="fn_obj_values"
              @change="fnObjChange" @saveChanges="saveChanges"
              @addNewObjectiveFunction="addNewObjectiveFunction"></bruna-fn-obj-constructor>

            <!--
              <div>{{ JSON.stringify(fn_obj_values, null, 2) }}</div>
            <b-button @click="validate2" variant="secondary" size="sm"
            >Validate</b-button
          >-->
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="new-objective-function-modal" ref="new-objective-function-modal" title="Crear Nueva Función Objetivo"
      button-size="sm" @ok="onOkCreateNewFunction" ok-title="Crear" cancel-title="Cancelar">

      <validation-observer ref="createNewFunctionValidation">

        <validation-provider #default="{ errors }" rules="required" name="nombre">

          <b-form-group description="Ingresa un nombre para la nueva función objetivo" label=""
            label-for="input-horizontal-name">
            <b-form-input id="input-horizontal-name" v-model="new_objective_function_modal_name"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>


        </validation-provider>

        <validation-provider #default="{ errors }" rules="required" name="descripción">

          <b-form-group description="Ingresa una descripción para la nueva función objetivo" label=""
            label-for="input-horizontal-description">
            <b-form-textarea id="input-horizontal-description"
              v-model="new_objective_function_modal_description"></b-form-textarea>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>


        </validation-provider>

      </validation-observer>


    </b-modal>

  </section>
</template>
   
<script>

import store from '@/store'

import { BRow, BCol, BFormGroup, BButton, BFormSelect } from "bootstrap-vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import BrunaFnObjConstructor from '@/views/brunacomponents/BrunaFnObjConstructor.vue';
import useApiServices from "@/services/useApiServices.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";

export default {
  props: {
  },
  components: {
    BRow,
    BCol,
    BrunaForm,
    BrunaFnObjConstructor,
    BrunaFnObjConstructor,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver
  },

  watch: {

    models: {
      handler(newValue, oldValue) {


        let field = this.findFieldByName(this.general.fields, 'service_name')

        let options = [
          { value: null, text: "Selecciona una opción" },
        ]

        this.models.forEach(model => {
          options.push({ value: model.id, text: model.name })
        })

        field.options = options
        field.value = this.selected_model_id

      },

    }
  },

  mounted() {

    this.getModels()

    store.commit('appConfig/SHOW_TOP_LOADING')
    setTimeout(() => {
      store.commit('appConfig/HIDE_TOP_LOADING')

    }, 6000)



  },

  methods: {

    onOkCreateNewFunction(bvModalEvent) {
      bvModalEvent.preventDefault()

      this.$refs['createNewFunctionValidation'].validate()
        .then(valid => {

          if (valid) {
            let data = {
              fn_obj_values: this.fn_obj_values,
              new_objective_function_modal_name: this.new_objective_function_modal_name,
              new_objective_function_modal_description: this.new_objective_function_modal_description,
              selected_model_id: this.selected_model_id
            }

            useApiServices.postCreateModelObjectiveFunction(data)
              .then((response) => {

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t('Información'),
                    icon: "UploadIcon",
                    variant: "success",
                    html: this.$t('Se han guardado los cambios con éxito.'),
                  },
                });

                this.getModels()

                this.new_objective_function_modal_name = ''
                this.new_objective_function_modal_description = ''


                this.$nextTick(() => {
                  this.$bvModal.hide('new-objective-function-modal')
                })

              })
              .catch((error) => {
                let message = error.response.data.message;

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t('Ocurrió un Error'),
                    icon: "UploadIcon",
                    variant: "danger",
                    html: message,
                  },
                });

              });

          }
          else {

          }

        })


    },

    addNewObjectiveFunction() {

      let model = this.models.find(model => model.id == this.selected_model_id)
      let model_objective_function = model.model_objective_functions.find(model_objective_function => model_objective_function.id == this.selected_tipo_funcion_objetivo_id)

      this.new_objective_function_modal_name = `${model_objective_function.name}_${new Date().getTime()}`
      this.new_objective_function_modal_description = ''


      this.$refs['new-objective-function-modal'].show()

    },

    saveChanges() {

      let model = this.models.find(model => model.id == this.selected_model_id)
      let model_objective_function = model.model_objective_functions.find(model_objective_function => model_objective_function.id == this.selected_tipo_funcion_objetivo_id)



      this.$bvModal.msgBoxConfirm(`¿Quieres guardar los cambios realizados en la función objetivo: ${model_objective_function.name} ?`, {
        title: 'Confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Guardar',
        cancelTitle: 'Cancelar',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {

          if (value) {
            let data = {
              fn_obj_values: this.fn_obj_values,
              selected_tipo_funcion_objetivo_id: this.selected_tipo_funcion_objetivo_id
            }

            useApiServices.postUpdateModelObjectiveFunction(data)
              .then((response) => {

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t('Información'),
                    icon: "UploadIcon",
                    variant: "success",
                    html: this.$t('Se han guardado los cambios con éxito.'),
                  },
                });


              })
              .catch((error) => {
                let message = error.response.data.message;

                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t('Ocurrió un Error'),
                    icon: "UploadIcon",
                    variant: "danger",
                    html: message,
                  },
                });

              });



          }
        })





    },


    findFieldByName(fields, name) {

      return fields.find(field => field.id == name)

    },

    fnObjChange() {


    },


    getModels() {
      useApiServices.getModelsIndex()
        .then(response => {

          this.models = response.data

          this.serviceNameChange(this.selected_model_id)

        })

    },


    getData() {
      let data = this.general.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})

      data.fn_obj_values = this.fn_obj_values
      data.metrics = this.metrics
      data.service_name = this.selected_model_service_name
      data.tipo_funcion_objetivo_name = this.selected_tipo_funcion_objetivo_name

      return data
    },

    serviceNameChange(selected_model_id) {

      if (selected_model_id == null) {
        return
      }

      let model = this.models.find(model => model.id == selected_model_id)

      this.selected_model_service_name = model.service_name

      //Del modelo selecionado se obtienen las metricas
      let metrics = []

      model.model_metrics.forEach(model_metric => {
        metrics.push({
          id: model_metric.key,
          name: model_metric.name,
          description: model_metric.description
        })
      })

      this.metrics = metrics

      //Del modelo seleccionado de obtienen los tipos de fobjs y se agregan al select
      let tipo_funcion_objetivo_field = this.findFieldByName(this.general.fields, 'tipo_funcion_objetivo')

      let options = [
        { value: null, text: "Selecciona una opción" },
      ]

      model.model_objective_functions.forEach(obj_function => {
        options.push({ value: obj_function.id, text: obj_function.name })
      })

      tipo_funcion_objetivo_field.options = options
      tipo_funcion_objetivo_field.value = this.selected_tipo_funcion_objetivo_id


    },

    generalChange(field) {
      if (field.id == "service_name") {
        this.selected_model_id = field.value

        this.serviceNameChange(this.selected_model_id)

      }

      if (field.id == "tipo_funcion_objetivo") {
        this.selected_tipo_funcion_objetivo_id = field.value

        let model = this.models.find(model => model.id == this.selected_model_id)
        let model_objective_function = model.model_objective_functions.find(model_objective_function => model_objective_function.id == this.selected_tipo_funcion_objetivo_id)

        this.fn_obj_values = model_objective_function.definition
        this.selected_tipo_funcion_objetivo_name = model_objective_function.name
        //this.new_objective_function_modal_name = model_objective_function.name



      }

    },

    validate() {
      return this.$refs.generalRef.validate()
    },

    validate2() {
      return this.$refs.bruna_fn_obj_cons.validate()
    },





  },


  data() {

    let debug = true || (this.$route.query.debug == '1')

    return {

      new_objective_function_modal_name: '',
      new_objective_function_modal_description: '',

      debug: debug,

      models: [],
      selected_model_id: null,
      selected_model_service_name: null,

      selected_tipo_funcion_objetivo_id: null,
      selected_tipo_funcion_objetivo_name: null,


      metrics: [],

      fn_obj_values: [
        {
          metrics: [],
          weight: 1,
          tipo: 'minimizar'
        }
      ],




      general: {
        card: {
          title: "",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'General',
            body: "<p>En esta vista se le asigna un nombre a la planificación, se escoge la función objetivo y el número de iteraciones (a mayor número de iteraciones, mayor tiempo de procesamiento pero mayor exactitud).</p><p>Tipo de Función Objetivo: Buscan la resolución de 7 variables: horas de producción, orden mínima, horas por formato por línea, phasing de entrega exceso, costo cambio de formato, cambio producción Sku y total horas de producción. Las variables terminadas en penalty están construidas bajo el enfoque de penalización, es decir, el modelo cuantifica como desfavorable un valor mayor.A cada variable es posible asignarle orden, para indicar la prioridad, un peso “weight” para indicar la importancia y un tipo de objetivo a alcanzar, ya sea minimizar o maximizar su valor.</p>"
          }
        },
        fields: [

          {
            label: "Nombre de la Planificación",
            type: "input",
            id: 'planification_name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: "Opt_" + Date.now()
          },

          {
            label: "Modelo",
            type: "select",
            id: 'service_name',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Tipo de Función Objetivo",
            type: "select",
            id: 'tipo_funcion_objetivo',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" }
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Número de Iteraciones",
            type: "select",
            id: 'max_generations',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opción" },
              { value: 1000, text: 1000 },
              { value: 2000, text: 2000 },
              { value: 3000, text: 3000 },
            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },


    };
  },
};
</script>
   